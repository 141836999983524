import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { AnchorButton, AnchorButtonProps, Button, ButtonProps, SpanButton } from '../components/Button';
import { Dialog, DialogContent, DialogProps, DialogTitle } from '../components/Dialog';
import { useDialogState } from './DialogState';
import { DropDown, IconProps, SortArrowUp } from '../components/Icon';
import { TextXSBold, TextXSRegular, TextMRegular, BodyRegular } from '../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../util/test-id';
import { DropdownMenuList, MenuListProps } from '../components/DropDown';
import { Centre } from '../components/CentreComboBox';
import { clearBusinessUnitData, clearSessionStorageNotifications } from '../boot';
import logoCE from '../public/logoCE.svg';
import logoCI from '../public/logoCI.svg';
import { AppBarTabButton, TabList } from '../components/Tabs';
import { TabInformation } from '../components/DatePicker/types';
import { useSignature } from '../singnature';
import {
  appTitleLink,
  appTitle,
  border,
  centerListTitle,
  surveyButton,
  userName as userNameStyle,
  userWidget,
  userWidgetIcon,
  businessWidget,
  businessWidgetIcon,
  dialogContent,
  dialogHeader,
  cancelButton,
  dialogActions,
  dialog,
  centreName,
  userWidgetLabel,
  userWidgetDropDown,
  businessWidgetLabel,
  businessWidgetDropDown,
  activeWidget,
  confirmationButton,
  businessWidgetText,
} from './styles.module.scss';
import { BusinessStreamName } from '../api';
import { isBusinessStreamCE } from '../centres/businessStreamCheck';
import { useCentres } from '../centres';
import { Route } from '../Routes';
import { Link } from 'react-router-dom';

interface AppTitle extends WithIProps<'a'> {
  businessStream: BusinessStreamName;
}

export const AppTitleLink: FC<AppTitle> = ({ businessStream, href = '', className, testID }) => (
  <Link to={href} className={clsx(appTitleLink, className)} {...dataTestID(testID)}>
    {!!businessStream && (
      <img
        src={isBusinessStreamCE(businessStream) ? logoCE : logoCI}
        alt={isBusinessStreamCE(businessStream) ? 'Cambridge English' : 'Cambridge International'}
        width="197"
        height="34"
      />
    )}
  </Link>
);

export const AppTitle: FC<AppTitle> = ({ businessStream, className, testID }) => (
  <span className={clsx(TextMRegular, appTitle, className)} {...dataTestID(testID)}>
    {!!businessStream && `Cambridge Assessment ${businessStream} Education`}
  </span>
);

export const GoToMyCambridgeDialog: FC<DialogProps> = ({ className, children, ...rest }) => (
  <Dialog className={clsx(className)} {...rest}>
    <DialogTitle className={dialogHeader} onClose={rest.onCancel} testID={childTestID(rest.testID, 'title')}>
      Go to My Cambridge
    </DialogTitle>
    <DialogContent className={dialogContent}>{children}</DialogContent>
  </Dialog>
);

interface GoToMyCambridgeProps extends ButtonProps {
  logout: (() => void) | null;
}

export const GoToMyCambridgeAction: FC<GoToMyCambridgeProps> = ({ logout, testID, ...rest }) => {
  const renderDialog = useDialogState();
  const onOpen = useCallback(() => {
    const close = (): void => renderDialog(null);
    logout &&
      renderDialog(
        <GoToMyCambridgeDialog
          className={dialog}
          testID={childTestID(testID, 'go-to-my-cambridge-dialog')}
          open
          onCancel={close}
        >
          <p>Are you sure you want to leave Global Listening?</p>
          <div className={dialogActions}>
            <Button
              type="button"
              variant="text"
              className={clsx(cancelButton)}
              onClick={close}
              testID={childTestID(testID, 'go-to-my-cambridge-dialog-action-cancel')}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                clearSessionStorageNotifications();
                clearBusinessUnitData();
                logout();
              }}
              testID={childTestID(testID, 'go-to-my-cambridge-dialog-action-logout')}
            >
              Yes, leave Global Listening
            </Button>
          </div>
        </GoToMyCambridgeDialog>,
      );
  }, [renderDialog, logout, testID]);

  return (
    <SpanButton
      size="small"
      variant="text"
      onClick={onOpen}
      testID={childTestID(testID, 'go-to-my-cambridge-button')}
      {...rest}
    >
      Go to My Cambridge
    </SpanButton>
  );
};

interface SupportButtonProps extends AnchorButtonProps {
  activeRoute?: string;
}

export const SupportButton: FC<SupportButtonProps> = ({ activeRoute, testID, className, ...rest }) => {
  if (activeRoute === Route.EULA || activeRoute === Route.BUSINESS) {
    return null;
  }
  const { businessStream } = useCentres();
  const url = isBusinessStreamCE(businessStream)
    ? 'https://support.cambridgeenglish.org/hc/en-gb/categories/360004210651-Global-Listening-Portal'
    : 'https://help.cambridgeinternational.org';
  return (
    <AnchorButton
      className={clsx(surveyButton, className)}
      size="small"
      variant="text"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      testID={`${testID}-survey-button`}
      {...rest}
    >
      Help
    </AnchorButton>
  );
};

export const ReportsButton: FC<AnchorButtonProps> = ({ testID, className, ...rest }) => {
  const { isDownloadActive } = useSignature();

  const modal = useDialogState();

  const onModalCancel = useCallback(() => {
    modal(null);
  }, [modal]);

  const onClick = useCallback(
    (href: string) => {
      if (isDownloadActive) {
        modal(<DiscardChangesDialog href={href} onCancel={onModalCancel} testID={testID} />);
      } else {
        location.assign(href);
      }
    },
    [modal, onModalCancel, isDownloadActive, testID],
  );

  return (
    <AnchorButton
      className={clsx(surveyButton, className)}
      size="small"
      variant="text"
      onClick={() => {
        onClick('/reports');
      }}
      testID={`${testID}-reports-button`}
      {...rest}
    >
      Reports
    </AnchorButton>
  );
};

export const UserAgreementButton: FC<AnchorButtonProps> = ({ testID, className, ...rest }) => (
  <AnchorButton
    className={clsx(surveyButton, className)}
    size="small"
    variant="text"
    href="https://www.cambridge.org/legal/global-listening-eula"
    target="_blank"
    rel="noopener noreferrer"
    testID={`${testID}-eula-button`}
    {...rest}
  >
    User agreement
  </AnchorButton>
);

export const GoToHomepageButton: FC<AnchorButtonProps> = ({ testID, className, ...rest }) => (
  <AnchorButton className={className} size="medium" href={`/`} testID={`${testID}-homepage-link-button`} {...rest}>
    Go to Main View
  </AnchorButton>
);

interface SeparatorProps extends Omit<WithIProps<'hr'>, 'role'> {
  role?: string;
}

export const Separator: FC<SeparatorProps> = ({ className, testID, role, ...rest }) => (
  <hr role={role} className={clsx(border, className)} {...dataTestID(testID, 'separator')} {...rest} />
);

interface CenterListTitleProps extends Omit<WithIProps<'span'>, 'role'> {
  role?: string;
}

export const CenterListTitle: FC<CenterListTitleProps> = ({ role, testID, className, ...rest }) => {
  return (
    <span
      role={role}
      className={clsx(TextXSBold, centerListTitle, className)}
      {...dataTestID(testID, 'list-title')}
      {...rest}
    >
      Switch Centre
    </span>
  );
};

interface UserWidgetProps extends ButtonProps {
  userName: string;
  centre: Centre | null;
}

export const UserWidget: FC<UserWidgetProps> = ({
  userName,
  centre,
  className,
  testID,
  children,
  id,
  active,
  ...rest
}) => {
  return (
    <Button
      id={id}
      active={active}
      labelProps={{ className: userWidgetLabel }}
      variant="text"
      className={clsx(TextXSRegular, userWidget, { [activeWidget]: active }, className)}
      {...dataTestID(testID, id)}
      {...rest}
    >
      <div>
        <span aria-label={`, active user ${userName}`} className={clsx(TextXSBold, userNameStyle)}>
          {userName}
        </span>
        <br />
        {centre && (
          <span
            aria-label={`, selected centre: ${centre.id} ${centre.name}`}
            className={clsx(TextXSRegular, centreName)}
          >
            {centre.id} {centre.name}
          </span>
        )}
        <UserWidgetIcon active={active} />
      </div>
      {children}
    </Button>
  );
};

interface UserWidgetIconProps extends IconProps {
  active?: boolean;
}

export const UserWidgetIcon: FC<UserWidgetIconProps> = ({ active }) => {
  return !active ? <DropDown className={userWidgetIcon} /> : <SortArrowUp className={userWidgetIcon} />;
};

export const UserWidgetDropDownMenu: FC<MenuListProps> = ({ className, ...rest }) => (
  <DropdownMenuList className={clsx(userWidgetDropDown, className)} {...rest} />
);

interface BusinessWidgetProps extends ButtonProps {
  optionName: string;
}

export const BusinessWidget: FC<BusinessWidgetProps> = ({
  className,
  testID,
  children,
  id,
  active,
  optionName,
  ...rest
}) => {
  return (
    <Button
      id={id}
      active={active}
      labelProps={{ className: businessWidgetLabel }}
      variant="text"
      className={clsx(businessWidget, { [activeWidget]: active }, className)}
      {...dataTestID(testID, id)}
      {...rest}
    >
      <div>
        <span className={clsx(BodyRegular, businessWidgetText)}>{optionName}</span>
        <BusinessWidgetIcon active={active} />
      </div>
      {children}
    </Button>
  );
};

interface BusinessWidgetIconProps extends IconProps {
  active?: boolean;
}

export const BusinessWidgetIcon: FC<BusinessWidgetIconProps> = ({ active }) => {
  return !active ? <DropDown className={businessWidgetIcon} /> : <SortArrowUp className={businessWidgetIcon} />;
};

export const BusinessWidgetDropDownMenu: FC<MenuListProps> = ({ className, ...rest }) => (
  <DropdownMenuList className={clsx(businessWidgetDropDown, className)} {...rest} />
);

interface TabsInfo extends TabInformation {
  href?: string;
}

interface TabsProps extends WithIProps<'div'> {
  id: string;
  tabs: TabsInfo[];
  activeTabPanel: string;
}

export const Tabs: FC<TabsProps> = ({ id, tabs, activeTabPanel, testID }) => {
  const { isDownloadActive } = useSignature();

  const modal = useDialogState();

  const onModalCancel = useCallback(() => {
    modal(null);
  }, [modal]);

  const onClick = useCallback(
    (href?: string) => {
      if (isDownloadActive) {
        modal(<DiscardChangesDialog href={href || ''} onCancel={onModalCancel} testID={testID} />);
      } else {
        location.assign(href || '');
      }
    },
    [modal, onModalCancel, isDownloadActive, testID],
  );

  return (
    <TabList aria-label="Navigation" role="navigation" testID={childTestID(testID, 'container')}>
      {tabs.map((tab) => (
        <AppBarTabButton
          id={tab.id}
          key={tab.id}
          group={id}
          tabId={tab.id}
          onClick={() => {
            onClick(tab.href);
          }}
          testID={childTestID(testID, `item-${tab.id}`)}
          active={activeTabPanel === tab.id}
        >
          {tab.name}
        </AppBarTabButton>
      ))}
    </TabList>
  );
};

interface DiscardChangesDialogProps extends DialogProps {
  href: string;
}

export const DiscardChangesDialog: FC<DiscardChangesDialogProps> = ({ href, testID, onCancel, className, ...rest }) => (
  <Dialog className={clsx(dialog, className)} open {...rest}>
    <DialogTitle onClose={onCancel} testID={childTestID(testID, 'title')}>
      Warning
    </DialogTitle>
    <DialogContent className={clsx(dialogContent, TextMRegular)} testID={childTestID(testID, 'content')}>
      File preparation will be discarded
      <div className={dialogActions}>
        <Button
          type="button"
          variant="text"
          className={clsx(cancelButton)}
          onClick={onCancel}
          testID={childTestID(testID, 'action-cancel')}
        >
          Cancel
        </Button>
        <AnchorButton
          className={confirmationButton}
          href={href}
          testID={childTestID(testID, 'action-discard-changes')}
          onClick={() => {
            clearSessionStorageNotifications();
          }}
        >
          Proceed and discard changes
        </AnchorButton>
      </div>
    </DialogContent>
  </Dialog>
);
