import { FC, Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ArticleCE,
  ArticleCI,
  BusinessStreamName,
  Download,
  Session,
  SessionCE,
  SessionCI,
  SupervisorSessionsCE,
  SupervisorSessionsCI,
} from '../api';
import { Button, SpanButton } from '../components/Button';
import { DropdownMenuContainer, DropdownMenuList } from '../components/DropDown';
import { ArrowDownward } from '../components/Icon';
import { ConnectedPagination, useCurrentPage } from '../components/Pagination';
import { useSignature } from '../singnature';
import {
  useSessionList,
  useSessionItem,
  useChildCentreSessionItem,
  useChildCentreSessionList,
  useSessions,
} from '../sessions';
import { useNotification, NotificationWaitingMessage } from '../notifications';
import {
  AvailableFromMessage,
  GetHelpMessage,
  SessionRowCE,
  SessionRowCI,
  SessionsTable,
  PreparingFileMessage,
  ReplacementFileWarning,
  NewFileWarning,
  NoSessionsMessageView,
  NoArticlesMessageView,
  Tabs,
  SessionsFilter,
  SessionsTabContainerView,
} from './views';
import { useCentres } from '../centres';
import { BusinessStream, isBusinessStreamCI } from '../centres/businessStreamCheck';
import { useBootState, useIsSupervisor } from '../boot';
import { Dayjs } from 'dayjs';
import { utcDate, toDateString } from '../components/Calendar';
import { WithIProps } from '../util/test-id';
import { parentChildTable } from './styles.module.scss';
import { GLRoles } from '../centres/roles';

interface FileInfo {
  product: string;
  specialArrangement: boolean;
  date: Dayjs;
  sitting: string;
  articleNo: string;
}

function getFileInfo(session: Session, businessStream: BusinessStreamName): FileInfo {
  let product = '';
  let specialArrangement = false;
  let sitting = '';
  let date;

  if (isBusinessStreamCI(businessStream)) {
    const sessionCI = session as SessionCI;
    const { qualificationShortName, assessmentId, componentId } = sessionCI.article;
    product = `${qualificationShortName} - ${assessmentId} - ${componentId}`;
    sitting = sessionCI.sitting || '';
    date = sessionCI.startDate ? sessionCI.startDate : sessionCI.date;
  } else {
    const sessionCE = session as SessionCE;

    product = sessionCE.article.product;
    specialArrangement = sessionCE.article.specialArrangement;
    sitting = sessionCE.sitting;
    date = sessionCE.date;
  }

  const fileInfo: FileInfo = {
    product,
    specialArrangement,
    sitting,
    articleNo: session.id,
    date: date,
  };

  return fileInfo;
}
interface DownloadDropdownProps {
  id: string;
  list: Download[];
  fileInfo: FileInfo;
  tabId: string;
}

interface DownloadActionProps {
  id: string;
  tabId: string;
}

interface CentreSessionRowProps {
  item: Session;
  tabId: string;
}

export const defaultTabList: SessionsTableTab[] = [
  {
    id: 'PARENT_CENTRE',
    name: 'Cambridge Associate',
  },
  {
    id: 'CHILD_CENTRE',
    name: 'Associate Centre',
  },
];

export const defaultTabId = 'PARENT_CENTRE';

export type SessionsTableTabId = 'PARENT_CENTRE' | 'CHILD_CENTRE';

export interface SessionsTableTab {
  id: string;
  name: string;
}

export interface CentreSessionsTableProps extends WithIProps<'div'> {
  tabId: string;
  isChild?: boolean;
}

const DownloadAction: FC<DownloadActionProps> = ({ id, tabId }) => {
  const item = useSessionItem(id);
  const childItem = useChildCentreSessionItem(id);
  const selectedItem = tabId === 'PARENT_CENTRE' ? item : childItem;
  const { businessStream } = useCentres();
  if (!selectedItem) return null;

  const {
    download,
    available: { from },
    isNew,
  } = selectedItem;

  const fileInfo = getFileInfo(selectedItem, businessStream);

  const isReplacementFile = useMemo(() => download && download.length && download[0].isReplacement, [download]);
  const isNewFile = useMemo(() => download && download.length && !download[0].isReplacement && isNew, [
    download,
    isNew,
  ]);

  const signing = !!download && useDownloadPending(download);

  if (!download) return <AvailableFromMessage date={from} />;
  if (!download.length) return <GetHelpMessage testID={`session-${id}`} />;
  if (signing) return <PreparingFileMessage />;

  return (
    <Fragment>
      {isReplacementFile && <ReplacementFileWarning testID={`session-${id}`} />}
      {isNewFile && <NewFileWarning testID={`session-${id}`} />}
      <DownloadDropdown id={id} list={download} fileInfo={fileInfo} tabId={tabId} />
    </Fragment>
  );
};

const useDownloadPending = (list: Download[]): boolean => {
  const {
    state: { requests },
  } = useSignature();
  return list.some((item) => !!requests[item.filename]);
};

export const DownloadDropdown: FC<DownloadDropdownProps> = ({ id, list, fileInfo, tabId }) => {
  const buttonId = `${id}-trigger`;
  const menuId = `${id}-menu`;
  const { businessStream } = useCentres();
  const session = useSessionItem(id);
  const childCentreSession = useChildCentreSessionItem(id);
  const selectedSession = tabId === 'PARENT_CENTRE' ? session : childCentreSession;
  const boot = useBootState();
  const assignedSessions = useMemo(() => (!boot.loading && boot.user?.globalListening?.sessions) || [], [boot]);
  const { selectedCentre } = useCentres();

  const activeDownload = useMemo(
    () =>
      !!selectedCentre &&
      selectedCentre.roles.some(
        (r) =>
          r === GLRoles.CE_EXAMS_OFFICER ||
          r === GLRoles.CE_ADMIN ||
          r === GLRoles.CI_ADMIN ||
          r === GLRoles.CI_EXAMS_OFFICER ||
          (r === GLRoles.SUPERVISOR &&
            selectedSession &&
            assignedSessions.some((s) => {
              const isSameCentreId = s.legacyCentreNumber === selectedCentre.id;
              const isSameArticleNumber = s.articleNumber === selectedSession.article.id;

              let productResult: boolean;
              if (isBusinessStreamCI(businessStream)) {
                const _s = s as SupervisorSessionsCI;
                const isSameExamDate = _s.examDate === toDateString(utcDate(selectedSession.date));
                productResult = isSameExamDate;
              } else {
                const _s = s as SupervisorSessionsCE;
                const isSameExamDate = _s.keyAssessmentDate === toDateString(utcDate(selectedSession.date));
                const isSameSitting = _s.sitting === (selectedSession as SessionCE).sitting;
                productResult = isSameExamDate && isSameSitting;
              }

              return isSameCentreId && isSameArticleNumber && productResult;
            })),
      ),
    [assignedSessions, selectedCentre, selectedSession, businessStream],
  );

  const { download } = useSignature();
  const { dispatch: dispatchNotification, messages, assignMessage } = useNotification();
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClick = useCallback(() => setOpen((value) => !value), []);

  const onLinkClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setOpen(false);
      const { filename, format, isZip } = event.currentTarget.dataset;
      if (filename) {
        download(filename, isZip === 'true');

        assignMessage(
          <NotificationWaitingMessage
            product={fileInfo.product}
            sa={fileInfo.specialArrangement}
            date={fileInfo.date}
            sitting={fileInfo.sitting}
            format={format}
          />,
          'FILE_BEING_PREPARED',
        );

        dispatchNotification([
          'ADD',
          {
            articleNo: fileInfo.articleNo,
            title: `Preparing file for ${fileInfo.product}`,
            body: messages.FILE_BEING_PREPARED,
          },
          'WAITING',
        ]);
      }
    },
    [download, dispatchNotification, assignMessage, messages, fileInfo],
  );

  return (
    <DropdownMenuContainer testID={`session-${id}-download-dropdown`} open={open} onClose={onClose} onOpen={onOpen}>
      <Button
        disabled={!activeDownload}
        id={buttonId}
        variant="text"
        size="small"
        startIcon={<ArrowDownward />}
        aria-haspopup="true"
        aria-controls={menuId}
        onClick={onClick}
        active={open}
        aria-expanded={open}
      >
        Download
      </Button>
      <DropdownMenuList
        visible={open}
        id={menuId}
        aria-labelledby={buttonId}
        items={list.map(({ format, filename, isZip }) => (
          <SpanButton
            size="small"
            variant="text"
            key={format}
            data-filename={filename}
            data-format={format}
            data-is-zip={isZip}
            onClick={onLinkClick}
          >
            .{format.toLocaleLowerCase()}
          </SpanButton>
        ))}
      />
    </DropdownMenuContainer>
  );
};

const CentreSessionRow: FC<CentreSessionRowProps> = ({ item, tabId }) => {
  const { businessStream, selectedCentre } = useCentres();

  if (isBusinessStreamCI(businessStream)) {
    const article = item.article as ArticleCI<'processed'>;
    return (
      <SessionRowCI
        tabId={tabId}
        testID={`session-${item.id}`}
        componentId={article.componentId}
        assessmentId={article.assessmentId}
        assessmentShortName={article.assessmentShortName}
        componentShortName={article.componentShortName}
        qualificationShortName={article.qualificationShortName}
        startDate={(item as SessionCI<'processed'>).startDate}
        examDate={item.date}
        sitting={(item as SessionCI<'processed'>).sitting}
        customers={(item as SessionCI<'processed'>).customers}
        actions={<DownloadAction id={item.id} tabId={tabId} />}
        isCIDemoCentre={selectedCentre?.id === 'DEMO'}
      />
    );
  }

  const article = item.article as ArticleCE<'processed'>;
  return (
    <SessionRowCE
      testID={`session-${item.id}`}
      product={article.product}
      date={item.date}
      sitting={(item as SessionCE<'processed'>).sitting}
      specialArrangements={article.specialArrangement}
      actions={<DownloadAction id={item.id} tabId={tabId} />}
    />
  );
};

export const SessionsTableContainer: FC<WithIProps<'div'>> = () => {
  const { data } = useSessions();
  const hasSessions = Object.keys(data?.items || {}).length !== 0;
  const hasChildSessions = Object.keys(data?.childCentreItems || {}).length !== 0;

  const initialTabIdValue = hasChildSessions && !hasSessions ? 'CHILD_CENTRE' : 'PARENT_CENTRE';
  const [tabIdValue, setTabIdValue] = useState<string>(sessionStorage.getItem('table') || initialTabIdValue);
  const { businessStream } = useCentres();

  useEffect(() => {
    sessionStorage.setItem('table', tabIdValue);
  }, [tabIdValue]);

  return hasChildSessions && businessStream === BusinessStream.INTERNATIONAL ? (
    <>
      {tabIdValue === 'PARENT_CENTRE' && <SessionsFilter />}
      {tabIdValue === 'CHILD_CENTRE' && <SessionsFilter isChild />}
      <Tabs
        id="sessions-tabs"
        hasChildSessions={hasChildSessions}
        businessStream={businessStream}
        activeTabPanel={tabIdValue}
        onTabSelect={setTabIdValue}
        tabs={defaultTabList.map(({ id, name }) => ({
          id,
          name,
          panel: (
            <SessionsTabContainerView>
              {id === 'PARENT_CENTRE' && <CentreSessionsTable tabId={tabIdValue} className={parentChildTable} />}
              {id === 'CHILD_CENTRE' && <CentreSessionsTable tabId={tabIdValue} isChild />}
            </SessionsTabContainerView>
          ),
        }))}
        testID="sessions-tabs"
      />
      {tabIdValue === 'PARENT_CENTRE' && <NoSessionsMessage />}
      {tabIdValue === 'CHILD_CENTRE' && <NoChildSessionsMessage />}
    </>
  ) : (
    <>
      <SessionsFilter />
      <CentreSessionsTable tabId="PARENT_CENTRE" />
      <NoSessionsMessage />
    </>
  );
};

export const CentreSessionsTable: FC<CentreSessionsTableProps> = ({ tabId, isChild, className }) => {
  const { businessStream } = useCentres();
  let totalPages, list, itemArr;

  if (isChild) {
    ({ totalChildCentreSessionPages: totalPages, childCentreSessionList: list } = useChildCentreSessionList());
    itemArr = list?.map((id) => useChildCentreSessionItem(id));
  } else {
    ({ totalPages, list } = useSessionList());
    itemArr = list?.map((id) => useSessionItem(id));
  }

  const rows = (itemArr as Session[])?.map((s: Session) => s && <CentreSessionRow item={s} key={s.id} tabId={tabId} />);

  return (
    <SessionsTable
      className={isChild ? parentChildTable : className}
      tabId={tabId}
      rows={rows}
      testID="sessions-list"
      aria-describedby="upcoming-sessions-title"
      pagination={totalPages > 1 && <ConnectedPagination aria-label="Session list navigation" total={totalPages} />}
      businessStream={businessStream}
    />
  );
};

export const NoSessionsMessage: FC = () => {
  const sessionList = useSessionList();
  const currentPage = useCurrentPage();
  const { list, totalPages } = sessionList;

  const { selectedCentre } = useCentres();
  const isSupervisor = useIsSupervisor(selectedCentre?.id || null);

  if (list && list.length) return null;
  else if (currentPage > totalPages) return <NoArticlesMessageView testID="no-articles" />;
  return <NoSessionsMessageView isSupervisor={isSupervisor} testID="no-sessions" />;
};

export const NoChildSessionsMessage: FC = () => {
  const currentPage = useCurrentPage();
  const { childCentreSessionList, totalChildCentreSessionPages } = useChildCentreSessionList();

  if (childCentreSessionList && childCentreSessionList.length) return null;
  else if (currentPage > totalChildCentreSessionPages) return <NoArticlesMessageView testID="no-articles" />;
  return <NoSessionsMessageView isSupervisor={false} testID="no-sessions" />;
};
